import Model from './welcomeModel';
import View from './welcomeView';
import PageController from '../PageController'
import 'slick-carousel';
require('slick-carousel/slick/slick.css');

class Controller extends PageController {
  constructor(model, view) {
    super();
    this._model = model;
    this._view = view;
    console.log(this.query)
  }
}

const Welcome = (function() {
  return {
    init: () => {
      const model = new Model();
      const view = new View(model);
      const controller = new Controller(model, view);
    }
  }
})()

export default Welcome;