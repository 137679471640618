const widget_2 = (() => {
    const setup = () => {

        $('.slider-big').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            autoplay: true,
            speed: 1000,
            infinite: true,
            draggable: false,
            // pauseOnFocus: false,
            // pauseOnHover: false,
            swipe: false,
            // touchMove: false,
            dots: true,
        })
            .on('beforeChange', (e, slick, currentSlide) => {
            let slide = slick.$slides[currentSlide];
            $(slide).closest('.widget-2-content').find('.slider-small').slick('slickGoTo', currentSlide+2);
            // $(slide).closest('.widget-2-content').find('.slick-active').next().addClass('slick-active-manual');
            if (currentSlide === slick.$slides.length - 1) {
                $(slide).closest('.widget-2-content').find('.full-bar').removeClass('full-bar');
            }
            // console.log(slick);
            if (currentSlide < slick.$slides.length - 1) {
                $(slick.$dots[0].childNodes[currentSlide]).addClass('full-bar');
            }
        });

        $('.slider-small').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            initialSlide: 1,
            draggable: false,
            swipe: false,
            touchMove: false
        })

        $('.small-item').on('mouseover', (e) => {
            $(e.currentTarget).find('.small-item-text').removeClass('hidden')
        }).on('mouseout', (e) => {
            $(e.currentTarget).find('.small-item-text').addClass('hidden')
        })

        $('.big-item').on('mouseover', (e) => {
            $(e.currentTarget).closest('.slider-big').find('.slick-active button').css('animation-play-state', 'paused');
        }).on('mouseout', (e) => {
            let element = $(e.currentTarget).closest('.slider-big').find('.slick-active button');
            element.css('animation-play-state', 'running');
            // TODO: induljon újra az animáció?
        })

        $('.slick-dots li').off();
    }

    return {
        init: () => {
            setup();
        }
    };
})()

export default widget_2;