import App from '../app';
import welcome from './welcome';
import widget_1 from './widgets/widget_1';
import widget_2 from './widgets/widget_2';
import widget_3 from './widgets/widget_3';
import '../../css/widget.css'

const Modules = {
    welcome,
    widget_1,
    widget_2,
    widget_3,
}

App.init(Modules);
