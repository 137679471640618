const widget_1 = (() => {
    const setup = () => {
        $('.slider').slick({
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            nextArrow: `<i class="fa fa-angle-right right-arrow"></i>`,
            prevArrow: `<i class="fa fa-angle-left left-arrow hidden"></i>`,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        }).on('beforeChange', (e, slick, current, next) => {
            let slide = slick.$slides[next];
            if (next === 0) {
                $(slide).closest('.widget-1-content').find('.left-arrow').addClass('hidden');
            } else if (next === slick.$slides.length - slick.getOption('slidesToShow')) {
                $(slide).closest('.widget-1-content').find('.right-arrow').addClass('hidden');
            } else {
                $(slide).closest('.widget-1-content').find('.left-arrow').removeClass('hidden')
                $(slide).closest('.widget-1-content').find('.right-arrow').removeClass('hidden')
            }
        });

        $('.go-to-more').click(() => {
            $('html, body').animate({
                scrollTop: $('#more-stories-title').offset().top
            }, 500, 'linear')
        })
    }

    return {
        init: () => {
            setup();
        }
    };
})()

export default widget_1;