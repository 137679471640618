import Component from '../Component';

class WelcomeView extends Component {
  constructor(model) {
    super();
    this._model = model;
    this.state = {};

    this.breakpoints = {
      1300: 3,
      991: 2,
      767: 1,
    }

    this.current = null;

    this.setupListeners();
    this.countUp();
    this.setupGallery();

    setTimeout(function() {
      $("*").removeClass('init');
    }, 100)
  }

  setupListeners() {
    $(window).resize(() => {
      this.setupGallery();
    })

    let hasMoreData = true;

    $(window).on('scroll', (e) => {
      if (!hasMoreData) {
        $(window).off('scroll');
        return;
      }

      let top = $(window).scrollTop();

      if (top < 20) {
        $('.arrow-up').addClass('noshow');
      }
      if (top > 100) {
        $('.arrow-up').removeClass('noshow');
      }

      if (top + $(window).height() > $(document).height() - 400) {
        let hiddenItems = $('.welcome-item.hidden');
        if (hiddenItems.length === 0) {
          hasMoreData = false;
          return;
        }

        let item_cols = [];
        Array.from($('[data-col]')).forEach((col, index) => {
          let data_col = $(col).attr('data-col');
          item_cols.push([]);
          item_cols[index] = Array.from($(`[data-col=${data_col}] .hidden`));
        })

        Array.from($('[data-col]')).forEach((col, index) => {
          let item = $(col).find('.hidden:first')
          let item2 = $(col).find('.hidden:eq(1)')
          $(item).removeClass('hidden');
          $(item2).removeClass('hidden');
          setTimeout(() => {
            $(item).removeClass('loading')
            $(item2).removeClass('loading')
          }, (index * 10))
        })
      }
    })

    $(".handle").click(function() {
      $('.footer').toggleClass('toggled');
    })

    $('.arrow-up').click(function() {
      $("html, body").stop().animate({scrollTop:0}, 500, 'swing', function() {

      });
    })
  }

  countUp() {
    let targets = Array.from($('[data-num]'));
    targets.forEach(element => {
      let id = $(element).attr('id');
      let count = parseInt($(element).attr('data-num'))
      this.animateValue(id, 0, count, 2000)
    });
  }

  animateValue(id, start, end, duration) {
    let obj = document.getElementById(id);
    let range = end - start;
    let minTimer = 50;
    let stepTime = Math.abs(Math.floor(duration / range));
    stepTime = Math.max(stepTime, minTimer);
    let startTime = new Date().getTime();
    let endTime = startTime + duration;
    let timer;
    function run() {
        let now = new Date().getTime();
        let remaining = Math.max((endTime - now) / duration, 0);
        let value = Math.round(end - (remaining * range));
        let valueString = new Intl.NumberFormat('en-US', {
          useGrouping: true,
          minimumFractionDigits: 0,
        }).format(value).replace(/,/g, ' ');
        obj.innerHTML = valueString;
        if (value == end) {
            clearInterval(timer);
        }
    }
    timer = setInterval(run, stepTime);
    run();
  }


  manageTextLengths(items, cols) {
    let textLengths = items.map(i => {
      return $(i).find('.item-body').text().length;
    })
    let sum = 0;
    textLengths.forEach(length => {
      sum += length;
    })
    let averageTextLength = sum / textLengths.length;
    let limit = (averageTextLength / 100) * 30; // 30%
    let tooMuch = [];
    textLengths.forEach((length, index) => {
      if (length > averageTextLength + limit) {
        tooMuch.push(index);
      }
    })
    items.splice(parseInt(tooMuch) + parseInt(cols), 0, "");
    return items;
  }

  setupGallery() {
    let items = Array.from($('.welcome-item-2'));
    items = items.sort((a, b) => {
      return $(a).attr('data-index') - $(b).attr('data-index');
    })
    let width = $(window).width();
    let num = 4;
    let breakpoint = Object.keys(this.breakpoints).find((bp, i, all) => {
      if (bp > width) {
        return all[i];
      }
    })
    if (breakpoint) { num = this.breakpoints[breakpoint] }
    items = this.manageTextLengths(items, num);
    let item_clones = items.map(i => {
      return $(i).clone();
    })
    if (this.current != num) {
      let columns = [];
      $('.welcome-carousel').html('<div class="row"></div>');
      for (let i = 0; i < num; i++) {
        columns.push([])
        $('.welcome-carousel').append(`
        <div class="col-sm-${12 / num}">
          <div class="row" data-col="${i}">
            <div class="col-sm-12"></div>
          </div>
        </div>
        `);
      }
      items.forEach((item, index) => {
        let col = index % num;
        columns[col].push(item);
        $(`.row[data-col=${col}] .col-sm-12`).append(item_clones[index]);
      })
      setTimeout(function () {
        $('.welcome-carousel').removeClass('loading');
      }, 300)
      this.current = num;
    }
  }
}

export default WelcomeView;