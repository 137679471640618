const widget_3 = (() => {
    const setup = () => {
        // alert('widget_3');

    }

    return {
        init: () => {
            setup();
        }
    };
})()

export default widget_3;